import { Button, Dropdown, Menu } from "antd";
import { useState } from "react";
import { AddFileModal } from "./AddFileModal.component";
import { AddFolderModal } from "./AddFolderModal.component";
import { FolderTree } from "./FolderTree.component";
import { ImportFileModal } from "./ImportFileModal.component";

interface MenuProps {
  setFolderModal: (val: boolean) => void;
  setFileModal: (val: boolean) => void;
  setImportModal: (val: boolean) => void;
}

const menu = ({ setFileModal, setFolderModal, setImportModal }: MenuProps) => (
  <Menu
    items={[
      {
        key: '1',
        label: (
          <Button
            type="text"
            size="small"
            onClick={() => setFolderModal(true)}
          >
            Folder
          </Button>
        ),
      },
      {
        key: '2',
        label: (
          <Button
            type="text"
            size="small"
            onClick={() => setFileModal(true)}>
            BEADL Protocol
          </Button>
        ),
      },
      {
        key: '3',
        label: (
          <Button
            type="text"
            size="small"
            onClick={() => setImportModal(true)}
          >
            Import Protocol
          </Button>
        ),
      },
    ]}
  />
);

export const ActionSidebar = () => {
  const [folderModal, setFolderModal] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [importModal, setImportModal] = useState(false);

  return (
    <div className="flex flex-wrap px-5 py-0 gap-y-4">
      <Dropdown
        overlay={menu({ setFileModal, setFolderModal, setImportModal })}
        overlayClassName="dropdown_br"
        className="w-full h-8 bg-white border border-black action_sidebar-btn"
      >
        <Button className="font-semibold">NEW...</Button>
      </Dropdown>

      <FolderTree />

      <AddFolderModal
        visible={folderModal}
        onCancel={() => setFolderModal(false)}
      />

      <AddFileModal
        visible={fileModal}
        onCancel={() => setFileModal(false)}
      />

      <ImportFileModal
        visible={importModal}
        onCancel={() => setImportModal(false)}
      />
    </div>
  );
};
