import Drawer from "@components/core/Drawer";
import { Edge } from "react-flow-renderer";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { useDispatch } from "react-redux";
import { deleteFlowEdge, updateFlowEdge } from "@store/beadlEditor";
import { Button, Input, Popconfirm, Switch, Typography } from "antd";
import { defaultBeadlEditorColors } from "../../tooltip.text";
import { DeleteFilled } from "@ant-design/icons";

export const SquareIcon = ({ color, active }: { color: string; active: boolean }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect
      width="25"
      height="25"
      fill={color}
      stroke={active ? '#ccc' : 'none'}
      strokeWidth={active ? 1 : 0}
    />
  </svg>
);

interface Props {
  visible: boolean;
  onClose: () => void;
  drawerData: { text: string; stroke: string; showLabel: boolean };
  setDrawerData: (data: { text: string; stroke: string; showLabel: boolean }) => void;
  usedColors: string[];
  data?: Edge
}

export const BeadlEdgeDrawer = ({ visible, onClose, data, usedColors, drawerData: { text, stroke, showLabel }, setDrawerData }: Props) => {
  const dispatch = useDispatch();

  const onSave = () => {
    if (!data) return;

    dispatch(updateFlowEdge({
      ...data,
      data: { text, style: { stroke }, showLabel },
    }));

    setDrawerData({ text: '', stroke: '', showLabel: true });
    onClose();
  };

  const onDelete = () => {
    if (!data?.id) return;

    dispatch(deleteFlowEdge(data.id));

    setDrawerData({ text: '', stroke: '', showLabel: true });
    onClose();
  };

  return (
    <Drawer
      title="BEADL Edge"
      placement="right"
      visible={visible}
      onClose={onClose}
      successAction={{ label: "Save", action: onSave }}
    >
      <div className="flex flex-col gap-5">
        <div className="flex gap-2 items-center">
          <Input
            placeholder="Label"
            value={text}
            onChange={(e) => setDrawerData({ text: e.target.value, stroke, showLabel })}
          />
          <Popconfirm title="Are you sure you want to delete?" okText="Yes" onConfirm={onDelete}>
            <Button type="link" danger icon={<DeleteFilled />} />
          </Popconfirm>
          <Switch
            checked={showLabel}
            onChange={(status) => setDrawerData({ text, stroke, showLabel: status })}
            checkedChildren="Show label"
            unCheckedChildren="Hide label"
          />
        </div>

        <HexColorPicker
          color={stroke}
          onChange={(color) => setDrawerData({ text, stroke: color, showLabel })}
        />

        <HexColorInput
          color={stroke}
          onChange={(color) => setDrawerData({ text, stroke: color, showLabel })}
          className="w-1/2"
        />

        <div className="flex gap-2.5 items-center">
          <Typography.Text>Color Used:</Typography.Text>
          {usedColors.map((color) => (
            <Button
              key={color}
              type="text"
              className="p-0"
              icon={<SquareIcon color={color} active={color === stroke} />}
              onClick={() => setDrawerData({ text, stroke: color, showLabel })}
            />
          ))}
        </div>

        <div className="flex gap-2.5 items-center">
          <Typography.Text>Default Colors:</Typography.Text>
          {defaultBeadlEditorColors.map((color) => (
            <Button
              key={color}
              type="text"
              className="p-0"
              icon={<SquareIcon color={color} active={color === stroke} />}
              onClick={() => setDrawerData({ text, stroke: color, showLabel })}
            />
          ))}
        </div>
      </div>
    </Drawer>
  );
};
