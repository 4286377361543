/* eslint-disable react-hooks/exhaustive-deps */
import { CombinedExportState } from "@customTypes/util";
import { BeadlEditorData } from "@customTypes/store/beadlEditor";
import { RootState } from "@store/index";
import Col from "antd/lib/grid/col";
import Row from "antd/lib/grid/row";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { BeadlConfigurationState } from "@customTypes/store/beadlConfiguration";
import { exportToFile } from "@util/fileIO/exportToFile";
import Divider from "antd/lib/divider";
import ProtocolSettings from "./ProtocolSettings";
import { COMBINED, CONFIGURATIONS, BEADL_STATE } from '../../../config';
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";
import { onUpload } from "@util/helpers/onUpload.helper";
import { api } from "../../../api";
import { useParams } from "react-router-dom";
import { Button, message, Tooltip } from "antd";
import { sendError, sendMessage } from "@util/helpers/notifications.helper";
import { ProtocolSettingsState } from '@customTypes/store/beadlConfiguration'
import { workspaceTooltip } from "../../../tooltip.text";
import { InteractionConnection } from "@customTypes/model/beadlConfiguration/interaction";


const ioOptions = [COMBINED, CONFIGURATIONS, BEADL_STATE];

const verifyConnection = (connection?: InteractionConnection) => {
  if (!connection) return false;
  if (connection.mapping_id && !connection.component_id) return true;
  if (connection.virtual_entity_id && !connection.virtual_entity_component_id) return true;

  return false;
};

const verifyProtocolSettings = ({ hardwareId, initialStateId, numberOfTrials, name }: ProtocolSettingsState, verifyState = false) => {
  if (!hardwareId) {
    message.error("Please select a hardware id");
  }

  if (verifyState && !initialStateId) {
    message.error("Please select an initial state");
  }

  if (
    !numberOfTrials
    || (isNaN(numberOfTrials as unknown as number) && numberOfTrials.toLowerCase() !== 'inf')
    || ((numberOfTrials as unknown as number) < 1)
  ){
    message.error("Only a valid positive number or 'INF' allowed for number of trials!");
    return false;
  }

  if (!name) {
    message.error("Please select a name");
  }

  if (!hardwareId || !numberOfTrials || !name) {
    return false;
  }

  if (verifyState && !initialStateId) {
    return false;
  }

  return true;
}

const verifyDependents = ({ actionDefinition, eventDefinition }: BeadlConfigurationState) => {
  const isHavingUncompleteConnection = actionDefinition.data.some(({ connection }) => verifyConnection(connection));
  const isHavingUncompleteEvent = eventDefinition.data.some(({ connection }) => verifyConnection(connection));

  if (isHavingUncompleteConnection) {
    message.error("Please complete all action connections");
  }

  if (isHavingUncompleteEvent) {
    message.error("Please complete all event connections");
  }

  if (isHavingUncompleteConnection || isHavingUncompleteEvent) return false;

  return true;
};

const LeftSiderContent = () => {
  const { id } = useParams();
  const [saveToS3Loading, setSaveToS3Loading] = useState(false);

  const ioFile = ioOptions[0];

  const beadlConfiguration = useSelector<RootState, BeadlConfigurationState>(
    (state) => state.beadlConfiguration
  );

  const activeTabData = useSelector<RootState, BeadlEditorData>(
    (state) => state.beadlEditor.tabNameDataMap[state.beadlEditor.activeTab]
  );

  const exportStateToFile = useCallback(async () => {
    const fileName = `${ioFile}.json`;
    let jsonData:
      | CombinedExportState
      | BeadlConfigurationState
      | BeadlEditorData;
    if (ioFile === COMBINED) {
      jsonData = {
        beadlConfiguration,
        beadlEditorData: activeTabData,
      } as CombinedExportState;
    } else if (ioFile === CONFIGURATIONS) {
      jsonData = beadlConfiguration;
    } else {
      jsonData = activeTabData;
    }

    if (!verifyProtocolSettings((jsonData as CombinedExportState).beadlConfiguration.protocolSettings)) {
      return;
    }

    await saveStateToS3();

    const json = JSON.stringify(jsonData, null, 2);
    const blobOptions = { type: "application/json" };
    exportToFile(json, fileName, blobOptions);
  }, [activeTabData, beadlConfiguration, ioFile]);

  const saveStateToS3 = useCallback(async () => {
    let jsonData:
      | CombinedExportState
      | BeadlConfigurationState
      | BeadlEditorData;
    if (ioFile === COMBINED) {
      jsonData = {
        beadlConfiguration,
        beadlEditorData: activeTabData,
      } as CombinedExportState;
    } else if (ioFile === CONFIGURATIONS) {
      jsonData = beadlConfiguration;
    } else {
      jsonData = activeTabData;
    }

    if (!verifyProtocolSettings((jsonData as CombinedExportState).beadlConfiguration.protocolSettings)) {
      return;
    }

    setSaveToS3Loading(true);

    try {
      const blob = new Blob([JSON.stringify(jsonData, null, 2)], { type: 'application/json' });

      const { fileKey: s3Key } = await onUpload(blob as File);

      const { status } = await api.patch(`/workspaces/files/${id}`, { s3Key });
      if (status !== 200) throw new Error();

      sendMessage('File saved successfully!');
    } catch (err) {
      sendError(err);
    } finally {
      setSaveToS3Loading(false);
    }
  }, [activeTabData, beadlConfiguration, ioFile, id]);

  const exportToXml = useCallback(async () => {
    if (!verifyProtocolSettings(beadlConfiguration.protocolSettings, true)) {
      return;
    }

    if (!verifyDependents(beadlConfiguration)) return;

    try {
      await saveStateToS3();

      const { data: xml } = await api.post<string>('/beadl', {
        beadlConfigurations: beadlConfiguration,
        beadlEditorState: activeTabData,
      });
      if (!xml) throw new Error('Unable to create XML')

      const fileName = "currentTrial.xml";
      const blobOptions = { type: "application/xml" };
      exportToFile(xml, fileName, blobOptions);
    } catch (err) { sendError(err); }
  }, [activeTabData, beadlConfiguration]);

  return (
    <Row className="px-5 pb-5">
      <Col span={24}>
        <Tooltip title={workspaceTooltip.saveProtocol}>
          <Button
            size="middle"
            onClick={saveStateToS3}
            icon={<SaveOutlined />}
            className="w-full truncate btn-icon"
            loading={saveToS3Loading}
          >
            Save Protocol
          </Button>
        </Tooltip>
      </Col>
      <Divider />
      <Col span={24}>
        <Tooltip title={workspaceTooltip.exportProtocol}>
          <Button
            size="middle"
            onClick={exportStateToFile}
            className="w-full truncate btn-icon"
            icon={<DownloadOutlined />}>
            Export Protocol
          </Button>
        </Tooltip>
      </Col>
      <Divider />
      <Col span={24}>
      <Tooltip title={workspaceTooltip.exportToXML}>
          <Button
            size="middle"
            onClick={exportToXml}
            className="w-full truncate">
            Export To XML
          </Button>
        </Tooltip>
      </Col>
      <Divider />
      <ProtocolSettings handleSave={exportToXml} />
    </Row>
  );
};

export default LeftSiderContent;
