import { TemplateFormType, Template, acceptImageExt } from '../../../../../types';
import { Button } from 'antd'
import { api } from '../../../../../api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { CustomInput, CustomTextarea, CustomUploader } from '@components/FormFields';
import * as Yup from 'yup';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { addTemplate } from '@store/template';

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required!'),
  fileKey: Yup.string().required('Template JSON file is required!'),
});

interface Props {
  onCancel: () => void;
}

export const AddForm = ({ onCancel }: Props) => {
  const [loading, setLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const dispatch = useDispatch();

  const cancelAndReset = () => {
    formik.resetForm();
    onCancel();
  };

  const onSubmit = async (input: TemplateFormType) => {
    setLoading(true);

    try {
      const { data } = await api.post<Template>('/templates', input);
      if (!data) throw new Error('');

      dispatch(addTemplate(data));
      sendMessage('Template added successfully!');

      cancelAndReset();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<TemplateFormType>({
    initialValues: { title: '', imageKey: '', fileKey: '', description: '' },
    validationSchema,
    onSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-wrap justify-between gap-4 px-2'>
        <div className='flex flex-col gap-1.5 w-2/5'>
          <label className='text-xs font-light' htmlFor="firstName">Title</label>
          <CustomInput formik={formik} name="title" placeholder="Title" />
        </div>

        <div className='flex flex-col w-2/5 gap-2 cs_uploader'>
          <label className='text-xs font-light' htmlFor="lastName">Template</label>
          <CustomUploader
            name="fileKey"
            formik={formik}
            placeholder="Upload BEADL File"
            btnClassName='cs_uploader-btn'
            setCustomLoading={setFileUploadLoading}
          />
        </div>
      </div>

      <div className='w-full h-5'/>

      <div className='flex flex-wrap justify-between gap-4 px-2'>
        <div className='flex flex-col gap-1.5 w-2/5'>
          <label className='text-xs font-light' htmlFor="firstName">Description</label>
          <CustomTextarea formik={formik} name="description" placeholder="Description" />
        </div>

        <div className='flex flex-col w-2/5 gap-2'>
          <label className='text-xs font-light' htmlFor="lastName">Image</label>
          <CustomUploader
            name="imageKey"
            accept={acceptImageExt}
            formik={formik}
            placeholder="Image"
            btnClassName='cs_uploader-btn-2'
            isPublic
            setCustomLoading={setImageUploadLoading}
            acceptRatio={[2, 1]}
          />
        </div>
      </div>


      <div className='flex justify-between w-full px-2 pt-10'>
        <Button type="ghost" onClick={cancelAndReset} className="w-2/5 btn-br-black">
          Cancel
        </Button>

        <Button
          loading={loading}
          type="ghost"
          htmlType="submit"
          className='w-2/5 btn-br-black'
          disabled={fileUploadLoading || imageUploadLoading}
        >
          Add template
        </Button>
      </div>
    </form>
  )
}
