import { RootState } from "@store/index";
import { EdgeProps, EdgeText, getMarkerEnd } from "react-flow-renderer";
import { useSelector } from "react-redux";

type BeadlEdgeProps = EdgeProps & {};

/**
 * # BeadlEdge
 *
 * BEADL Edge is used to draw custom edges between components. The main reason to not use a default type while drawing
 * edges was to not let edges go through the nodes. A basic calculation is run in order to avoid overlapping elements on
 * the editor. Let's call the vertical difference between source node's and target node's left top points as `yDiff`, and
 * horizontal difference as `xDiff`. We know that width of the each `Node` is specified as `400px`, and we want to put at
 * least `10px` gap between the `Node` and vertical part of the `Edge`. We defined a variable `rate` which is equal to
 * `1 + yDiff / 100`, in order to increase the margin proportional the `yDiff`. So;
 *
 * - If `xDiff <= -(400 + 10 * rate)` (`Target Node` is located at far away left of the `Source Node` ) -> `BeadlEdge` will
 *   start from the left side of the `Source Node`, and will end at the right side of the `Target Node`.
 * - If `-400 - 10 * rate < xDiff && xDiff < 0` (`Target Node` is located at the left of the `Source Node`, but has
 *   overlapping parts on the horizontal plane) -> `BeadlEdge` will start from the left side of the `Source Node`, and will
 *   end at the left side of the `Target Node`.
 * - If `0 <= xDiff && xDiff < 400 + 10 * rate` (`Target Node` is located at the right side of the `Source Node`, but has
 *   overlapping parts on the horizontal plane) -> `BeadlEdge` will start from the left side of the `Source Node`, and will
 *   end at the left side of the `Target Node`.
 * - Else (`Target Node` is located at far away right of the `Source Node`) -> `BeadlEdge` will start from the right side
 *   of the `Source Node`, and will end at the left side of the `Target Node`.
 *
 * @category @components/BeadlEditor
 * @param {BeadlEdgeProps} props
 * @returns
 */
export const BeadlEdge = (props: BeadlEdgeProps) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    data = {},
    arrowHeadType,
    markerEndId,
  } = props;
  const showAllLabels = useSelector<RootState>((state) => state.beadlEditor.showAllLabels) as boolean;

  const xDiff = targetX - sourceX;
  const yDiff = Math.abs(targetY - sourceY);
  let labelXAxis: number;

  let tempPoints: string;
  const rate = 1 + yDiff / 100;
  const textLength = data?.text && data.text.length > 20 ? data.text.length : 0;

  if (xDiff <= -(400 + 10 * rate)) {
    tempPoints = `${sourceX}, ${sourceY} ${sourceX - 10 * rate}, ${sourceY} ${
      sourceX - 10 * rate
    },${targetY} ${targetX + 400},${targetY}`;
    labelXAxis = sourceX - ((textLength + 10) * rate);
  } else if (-400 - 10 * rate < xDiff && xDiff < 0) {
    tempPoints = `${sourceX}, ${sourceY} ${targetX - 10 * rate}, ${sourceY} ${
      targetX - 10 * rate
    },${targetY} ${targetX},${targetY}`;
    labelXAxis = targetX - ((textLength + 10) * rate);
  } else if (0 <= xDiff && xDiff < 400 + 10 * rate) {
    tempPoints = `${sourceX}, ${sourceY} ${sourceX - 10 * rate}, ${sourceY} ${
      sourceX - 10 * rate
    },${targetY} ${targetX},${targetY}`;
    labelXAxis = sourceX - ((textLength + 10) * rate);
  } else {
    tempPoints = `${sourceX + 400}, ${sourceY} ${
      sourceX + 400 + 10 * rate
    }, ${sourceY} ${
      sourceX + 400 + 10 * rate
    },${targetY} ${targetX},${targetY}`;
    labelXAxis = sourceX + 400 + ((textLength + 10) * rate);
  }

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <>
      <polyline
        id={id}
        style={data?.style}
        className="react-flow__edge-path"
        points={tempPoints}
        markerEnd={markerEnd}
      />
      {showAllLabels && data.showLabel && data.text && (
        <EdgeText
          label={data.text}
          x={labelXAxis}
          y={sourceY}
          labelShowBg
          labelBgPadding={[2, 4]}
          labelBgBorderRadius={2}
          labelStyle={{ fill: 'white' }}
          labelBgStyle={{ fill: 'grey' }}
        />
      )}
    </>
  );
};

export default BeadlEdge;
