import React, { useCallback, useEffect, useState } from "react";
import Input from "@components/core/Input";
import { BeadlEditorDrawerFormProps } from "@customTypes/components/BeadlEditor/Form";
import EventSelector from "@components/selectors/EventSelector";
import ActionSelector from "@components/selectors/ActionSelector";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";

import { DeleteOutlined } from "@ant-design/icons";

import { BeadlEditorDrawerState } from "@customTypes/store/beadlEditor";
import { setFormData } from "@store/beadlEditor";
import { STATE_EXPIRED_EVENT_ID } from "@util/constants";
import StateSelector from "@components/selectors/StateSelector";
import { BeadlStateEvent } from "@customTypes/model/beadlEditor/beadlState/beadlStateEvent";
import { BeadlStateAction } from "@customTypes/model/beadlEditor/beadlState/beadlStateAction";
import Form, { useForm } from "antd/lib/form/Form";
import FormItem from "@components/BeadlEditor/Form/Item";
import { Button, Col, Row, AutoComplete, Tooltip } from "antd";
import ResourceComponentValue from "@components/ResourceComponentValue";
import { ActionDefinitionState, ArgumentDefinitionState, EventDefinitionState } from "@customTypes/store/beadlConfiguration";
import { noEmptyRule } from "@util/ruleGenerators";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import { getResourceComponent } from "./util";
import { beadlSelector } from "@store/beadl";

type CollectionType = "actions" | "events";
type CollectionTemplate = BeadlStateEvent | BeadlStateAction;

/**
 * # BeadlEditorDrawerForm
 *
 * `BeadlEditorDrawerForm` is used to add / edit / update / delete states by a form. This form uses globally stored
 * `drawerState` (`store.beadlEditor.drawerState.formData`). In this form, there are 2 types of collections: `BeadlEvent`s
 * and `BeadlAction`s. `addToCollection`, `removeFromCollection` and `updateTheCollection` methods are used to control
 * those collections. `handleActionAdd` is used to add a new record to the `BeadlAction` collection, and `handleEventAdd`
 * is used to add a new record to the `BeadlAEvent` collection. Also, `handleEventLinkChange` is used to build a connection
 * between the current state and another state from the drawer (Connection is built when the `formData` is saved).
 * @category @components/BeadlEditor
 * @param props
 * @returns {JSX.Element}
 */
export const BeadlEditorDrawerForm = (props: BeadlEditorDrawerFormProps) => {
  const dispatch = useDispatch();

  const existingStates = useSelector<RootState, string[]>((state) =>
    Object.keys(
      state.beadlEditor.tabNameDataMap[state.beadlEditor.activeTab]
        .stateNameIndexMap
    )
  );

  const { data: argumentDefinitions } = useSelector<RootState, ArgumentDefinitionState>(
    (state) => state.beadlConfiguration.argumentDefinition
  );

  const { formData } = useSelector<RootState, BeadlEditorDrawerState>(
    (state) => state.beadlEditor.drawerState
  );

  const [existingStatesSet, setExistingStatesSet] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    const nextExistingStatesSet = new Set(existingStates);
    nextExistingStatesSet.delete(formData.name);
    setExistingStatesSet(nextExistingStatesSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionDefinition = useSelector<RootState, ActionDefinitionState>(
    (state) => state.beadlConfiguration.actionDefinition
  );
  const { data: actions, dataIndexMappedById: actionIdIndexMap } =
    actionDefinition;
  const eventDefinition = useSelector<RootState, EventDefinitionState>(
    (state) => state.beadlConfiguration.eventDefinition
  );

  const {
    resourceComponents: {
      data: resourceComponents,
      dataIndexMappedById: resourceComponentIndexMappedById,
    },
  } = useSelector(beadlSelector);

  const { data: events, dataIndexMappedById: eventIdIndexMap } =
    eventDefinition;

  const { form: formProp, handleSave } = props;

  const [form] = useForm<typeof formData>(formProp);

  const addToCollection = useCallback(
    <T extends unknown = CollectionTemplate>(
      type: CollectionType,
      argument: T
    ) => {
      const collection = [...(formData[type] || []), argument];
      const payload = {
        ...formData,
        actions:
          type === "actions"
            ? (collection as BeadlStateAction[])
            : formData.actions,
        events:
          type === "events"
            ? (collection as BeadlStateEvent[])
            : formData.events,
      };
      dispatch(setFormData(payload));
    },
    [dispatch, formData]
  );

  const removeFromCollection = useCallback(
    (type: CollectionType, index: number) => {
      let collection: CollectionTemplate[] = [];
      if (type === "actions") {
        // remove from actions
        collection = [...(formData.actions || [])];
        collection.splice(index, 1);
      } else if (type === "events") {
        // remove from events
        collection = [...(formData.events || [])];
        collection.splice(index, 1);
      }
      const payload = {
        ...formData,
        actions:
          type === "actions"
            ? (collection as BeadlStateAction[])
            : formData.actions,
        events:
          type === "events"
            ? (collection as BeadlStateEvent[])
            : formData.events,
      };
      dispatch(setFormData(payload));
    },
    [dispatch, formData]
  );

  const updateTheCollection = useCallback(
    <T extends unknown = CollectionTemplate>(
      type: CollectionType,
      index: number,
      argument: T
    ) => {
      let collection: CollectionTemplate[] = [];
      if (type === "actions") {
        // update the actions
        collection = [...(formData.actions || [])];
        collection.splice(index, 1, argument as BeadlStateAction);
      } else if (type === "events") {
        // update the events
        collection = [...(formData.events || [])];
        collection.splice(index, 1, argument as BeadlStateEvent);
      }
      const payload = {
        ...formData,
        actions:
          type === "actions"
            ? (collection as BeadlStateAction[])
            : formData.actions,
        events:
          type === "events"
            ? (collection as BeadlStateEvent[])
            : formData.events,
      };
      dispatch(setFormData(payload));
    },
    [dispatch, formData]
  );

  const handleActionAdd = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      addToCollection("actions", { action_id: actions[0].id });
    },
    [actions, addToCollection]
  );

  const handleEventAdd = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      addToCollection("events", { event_id: events[0].id });
    },
    [addToCollection, events]
  );

  const handleEventLinkChange = useCallback(
    (index: number, state_id: BeadlState["id"]) => {
      const argument: BeadlStateEvent = {
        ...(formData.events || [])[index],
        triggered_state_id: state_id,
      };
      updateTheCollection("events", index, argument);
    },
    [formData.events, updateTheCollection]
  );

  const handleSubmit = useCallback(() => {
    handleSave(formData);
  }, [formData, handleSave]);

  useEffect(() => {
    form.setFieldsValue({ ...formData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={formData}
    >
      <FormItem
        name="name"
        label="State Name"
        rules={[
          noEmptyRule("Name"),
          {
            validator: async (_, value) => {
              if (existingStatesSet.has(value)) {
                throw new Error(
                  "State name should be different than other state names"
                );
              }
            },
          },
        ]}
      >
        <Input
          value={formData.name}
          onChange={(event) =>
            dispatch(setFormData({ ...formData, name: event.target.value }))
          }
          type="text"
          name="name"
          id="name"
          autoComplete="state-name"
        />
      </FormItem>

      <FormItem name={["duration", "value"]} label="Maximum Duration">
        <Tooltip
          title={
            'If state timer value is not empty or equal to "inf", ' +
            '"stateTimer" event will be the first event for this state'
          }
        >
          <AutoComplete
            value={formData.duration.value}
            onChange={(value) => {
              dispatch(setFormData({ ...formData, duration: { value } }))
            }}
            placeholder="Maximum duration"
            id="timer"
            options={
              argumentDefinitions
                .map((a) => ({ value: a.name, label: a.name }))
                .concat([{ value: "inf", label: "inf" }])
            }
            className="w-full"
            showSearch
            allowClear
          />
        </Tooltip>
      </FormItem>
      <div className="ant-col ant-form-item-label">Output actions</div>
      {formData.actions?.map((action, index) => (
        <Row key={`action-form-item-${index}`} gutter={8}>
          <Col xs={4} md={2}>
            <Button
              htmlType="button"
              onClick={() => removeFromCollection("actions", index)}
              icon={<DeleteOutlined />}
            />
          </Col>
          <Col xs={20} md={14}>
            <FormItem
              name={["actions", index, "action_id"]}
              rules={[noEmptyRule("Action")]}
            >
              <ActionSelector
                key={`beadl-editor-form-action-${index}`}
                selected={action.action_id as unknown as number}
                onChange={(id) =>
                  updateTheCollection("actions", index, {
                    ...(formData.actions || [])[index],
                    action_id: id,
                  })
                }
              />
            </FormItem>
          </Col>
          {action.action_id !== undefined && (
            <Tooltip
              title={
                getResourceComponent(
                  resourceComponents,
                  resourceComponentIndexMappedById,
                  (actions[actionIdIndexMap[action.action_id]] || {}).connection
                    ?.component_id
                )?.info || ""
              }
            >
              <Col xs={24} md={8}>
                <FormItem
                  name={["actions", index, "value"]}
                  rules={[noEmptyRule("Value")]}
                >
                  <ResourceComponentValue
                    componentId={
                      actions[actionIdIndexMap[action.action_id]].connection
                        ?.component_id
                    }
                    virtualComponentId={
                      actions[actionIdIndexMap[action.action_id]].connection
                        ?.virtual_entity_component_id
                    }
                    value={action?.value}
                    onChange={(value) =>
                      updateTheCollection("actions", index, {
                        ...(formData.actions || [])[index],
                        value,
                      })
                    }
                  />
                </FormItem>
              </Col>
            </Tooltip>
          )}
        </Row>
      ))}
      <Row justify="center">
        <Col span={24}>
          <Button className="w-full" onClick={handleActionAdd}>
            Add new output action
          </Button>
        </Col>
      </Row>
      <div className="ant-col ant-form-item-label">Input events</div>
      {formData.events?.map((event, index) => (
        <Row key={`event-form-item-${index}`} gutter={8}>
          <Col xs={4} md={2}>
            <Button
              htmlType="button"
              onClick={() => removeFromCollection("events", index)}
              disabled={(event.event_id as unknown as number) === STATE_EXPIRED_EVENT_ID}
              icon={<DeleteOutlined />}
            />
          </Col>
          <Col xs={20} md={8}>
            <FormItem
              name={["events", index, "event_id"]}
              rules={[noEmptyRule("Event")]}
            >
              <EventSelector
                key={`beadl-editor-form-event-${index}`}
                selected={event.event_id as unknown as number}
                onChange={(id) =>
                  updateTheCollection("events", index, {
                    ...(formData.events || [])[index],
                    event_id: id,
                  })
                }
                disabled={(event.event_id as unknown as number) === STATE_EXPIRED_EVENT_ID}
              />
            </FormItem>
          </Col>
          {event.event_id !== undefined && (event.event_id as unknown as number) !== -1 && (
            <Col xs={24} md={4}>
              <FormItem
                name={["events", index, "value"]}
                rules={[noEmptyRule("Value")]}
              >
                <ResourceComponentValue
                  componentId={
                    events[eventIdIndexMap[event.event_id]].connection
                      ?.component_id
                  }
                  value={event?.value}
                  onChange={(value) =>
                    updateTheCollection("events", index, {
                      ...(formData.events || [])[index],
                      value,
                    })
                  }
                  virtualComponentId={
                    events[eventIdIndexMap[event.event_id]].connection
                      ?.virtual_entity_component_id
                  }
                />
              </FormItem>
            </Col>
          )}
          <Col xs={24} md={10}>
            <FormItem name={["events", index, "triggered_state_id"]}>
              <StateSelector
                selected={event.triggered_state_id}
                onChange={(linkedState) =>
                  handleEventLinkChange(index, linkedState)
                }
              />
            </FormItem>
          </Col>
        </Row>
      ))}
      <Row justify="center">
        <Col span={24}>
          <Button className="w-full" onClick={handleEventAdd}>
            Add new input event
          </Button>
        </Col>
      </Row>
      <Button hidden htmlType="submit">
        Save
      </Button>
    </Form>
  );
};

export default BeadlEditorDrawerForm;
