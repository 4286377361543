import { CustomInput, CustomUploader } from "@components/FormFields";
import { addWorkspaceFile, myWorkspaceSelector } from "@store/workspace";
import { Modal } from "antd";
import { api } from "../../../api";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkspaceFile } from "../../../types";
import * as Yup from 'yup';
import { sendError, sendMessage } from "@util/helpers/notifications.helper";

const validationSchema = Yup.object().shape({
  title: Yup.string().required('File name is required!'),
  s3Key: Yup.string().required('Upload file is required!'),
});

interface FormType {
  title: string;
  s3Key: string;
}

interface Props {
  visible: boolean;
  onCancel: () => void;
}

export const ImportFileModal = ({ visible, onCancel }: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { currentOpenFolder } = useSelector(myWorkspaceSelector);

  const onSubmit = async ({ title, s3Key }: FormType, { resetForm }: FormikHelpers<FormType>) => {
    setLoading(true);
    try {
      const folder = currentOpenFolder?.id;

      const { data } = await api.post<WorkspaceFile>('/workspaces/files', { folder, title, s3Key })
      if (!data) throw new Error('Unable to create folder!');

      sendMessage('File imported successfully!');

      dispatch(addWorkspaceFile(data));
      resetForm();
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<FormType>({
    initialValues: { title: '', s3Key: '' },
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Import Beadl File"
        visible={visible}
        onCancel={onCancel}
        okButtonProps={{ loading, onClick: () => formik.handleSubmit() }}
      >
        <div className="flex flex-col gap-2.5">
          <CustomInput
            name="title"
            formik={formik}
            placeholder="File name"
            onPressEnter={formik.submitForm}
          />
          <CustomUploader
            name="s3Key"
            formik={formik}
            placeholder="Beadl Json File"
            setCustomLoading={setLoading}
          />
        </div>
      </Modal>
    </form>
  );
};
